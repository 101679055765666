import { environment } from "src/environments/environment";

const base_url = environment.api;

export class Usuario {

    constructor(

      public email:string,
      public created_at:string,
      public is_active:string,
      public lastname:string,
      public name:string,
      public password:string,
      public type_id:number,
      public username:string,
      public profile:string,
      public avatar?:string,
      public id?:string
    ) {}

    get imagenUrl() {

        if ( !this.avatar ) {
            return `${ base_url }/upload/usuarios/no-image`;
        } else if ( this.avatar.includes('https') ) {
            return this.avatar;
        } else if ( this.avatar ) {
            return `${ base_url }/upload/usuarios/${ this.avatar }`;
        } else {
            return `${ base_url }/upload/usuarios/no-image`;
        }
    }
}
