import * as CryptoJS from 'crypto-js';



export function Decrypt(value) {
    var key = '0123456789abcdef';

    if (value) {
        const decrypted = CryptoJS.AES.decrypt(value, key);
        return decrypted.toString(CryptoJS.enc.Utf8);
    }

}

export function Encrypt(value) {
    // let bytes  = CryptoJS.AES.decrypt(value, 'Cr1s4l1bb33y240287m34b235CL12344345');
    return CryptoJS.AES.encrypt(value, '0123456789abcdef').toString();
}


export function detectBrowserName() {
    if (navigator.userAgent.search("MSIE") >= 0) {
        return 'msie';
    }
    //Check if browser is Chrome
    else if (navigator.userAgent.search("Chrome") >= 0) {
        return 'chrome';
    }
    //Check if browser is Firefox
    else if (navigator.userAgent.search("Firefox") >= 0) {
        return 'firefox';
    }
    //Check if browser is Safari
    else if (navigator.userAgent.search("Safari") >= 0 && navigator.userAgent.search("Chrome") < 0) {
        return 'safari';
    }
    //Check if browser is Opera
    else if (navigator.userAgent.search("Opera") >= 0) {
        return 'opera';
    }
}

export function formaspago(texto) {
    let array = {
        'formas': [
            { 'clave': '01', 'description': 'Efectivo' },
            { 'clave': '02', 'description': 'Cheque nominativo' },
            { 'clave': '03', 'description': 'Transferencia electrónica de fondos' },
            { 'clave': '04', 'description': 'Tarjeta de crédito' },
            { 'clave': '05', 'description': 'Monedero electrónico' },
            { 'clave': '06', 'description': 'Dinero electrónico' },
            { 'clave': '08', 'description': 'Vales de despensa' },
            { 'clave': '12', 'description': 'Dación en pago' },
            { 'clave': '13', 'description': 'Pago por subrogación' },
            { 'clave': '14', 'description': 'Pago por consignación' },
            { 'clave': '15', 'description': 'Condonación' },
            { 'clave': '17', 'description': 'Compensación' },
            { 'clave': '23', 'description': 'Novación' },
            { 'clave': '24', 'description': 'Confusión' },
            { 'clave': '25', 'description': 'Remisión de deuda' },
            { 'clave': '26', 'description': 'Prescripción o caducidad' },
            { 'clave': '27', 'description': 'A satisfacción del acreedor' },
            { 'clave': '28', 'description': 'Tarjeta de débito' },
            { 'clave': '29', 'description': 'Tarjeta de servicios' },
            { 'clave': '30', 'description': 'Aplicación de anticipos' },
            { 'clave': '99', 'description': 'Por definir' }
        ]

    }



    let type = array.formas.filter(el => el.clave === texto);
    let typeuse = type[0];
    let textcfdi = `${typeuse['clave']} - ${typeuse['description']}`;
    return textcfdi;
}

export function tipoComprobante(texto) {
    let array = {
        'tipocom': [
            { 'clave': 'I', 'description': 'Ingreso' },
            { 'clave': 'E', 'description': 'Egreso' },
            { 'clave': 'T', 'description': 'Traslado' },
            { 'clave': 'N', 'description': 'Nómina' },
            { 'clave': 'P', 'description': 'Pago' },
        ]
    }
    let type = array.tipocom.filter(el => el.clave === texto);
    let typeuse = type[0];
    let textcfdi = `${typeuse['clave']} - ${typeuse['description']}`;
    return textcfdi;
}

export function regimenFiscal(texto) {
    let array = {
        'regimen': [
            { 'clave': '601', 'description': 'General de Ley Personas Morales' },
            { 'clave': '603', 'description': 'Personas Morales con Fines no Lucrativos' },
            { 'clave': '605', 'description': 'Sueldos y Salarios e Ingresos Asimilados a Salarios' },
            { 'clave': '606', 'description': 'Arrendamiento' },
            { 'clave': '607', 'description': 'Régimen de Enajenación o Adquisición de Bienes' },
            { 'clave': '608', 'description': 'Demás ingresos' },
            { 'clave': '609', 'description': 'Consolidación' },
            { 'clave': '610', 'description': 'Residentes en el Extranjero sin Establecimiento Permanente en México' },
            { 'clave': '611', 'description': 'Ingresos por Dividendos (socios y accionistas)' },
            { 'clave': '612', 'description': 'Personas Físicas con Actividades Empresariales y Profesionales' },
            { 'clave': '614', 'description': 'Ingresos por intereses' },
            { 'clave': '615', 'description': 'Régimen de los ingresos por obtención de premios' },
            { 'clave': '616', 'description': 'Sin obligaciones fiscales' },
            { 'clave': '620', 'description': 'Sociedades Cooperativas de Producción que optan por diferir sus ingresos' },
            { 'clave': '621', 'description': 'Incorporación Fiscal' },
            { 'clave': '622', 'description': 'Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras' },
            { 'clave': '623', 'description': 'Opcional para Grupos de Sociedades' },
            { 'clave': '624', 'description': 'Coordinados' },
            { 'clave': '625', 'description': 'Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas' },
            { 'clave': '626', 'description': 'Régimen Simplificado de Confianza' },
            { 'clave': '628', 'description': 'Hidrocarburos' },
            { 'clave': '629', 'description': 'De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales' },
            { 'clave': '630', 'description': 'Enajenación de acciones en bolsa de valores' },
        ]
    };
    let type = array.regimen.filter(el => el.clave === texto);
    let typeuse = type[0];
    let textcfdi = `${typeuse['clave']} - ${typeuse['description']}`;
    return textcfdi;
}

export function filteruseCfdi(tipo) {

    let array = {
        'usecfdi': [
            { 'clave': 'G01', 'description': 'Adquisición de mercancías' },
            { 'clave': 'G02', 'description': 'Devoluciones, descuentos o bonificaciones ' },
            { 'clave': 'G03', 'description': 'Gastos en general ' },
            { 'clave': 'I01', 'description': 'Construcciones ' },
            { 'clave': 'I02', 'description': 'Mobiliario y equipo de oficina por inversiones ' },
            { 'clave': 'I03', 'description': 'Equipo de transporte ' },
            { 'clave': 'I04', 'description': 'Equipo de cómputo y accesorios ' },
            { 'clave': 'I05', 'description': 'Dados, troqueles, moldes, matrices y herramental ' },
            { 'clave': 'I06', 'description': 'Comunicaciones telefónicas ' },
            { 'clave': 'I07', 'description': 'Comunicaciones satelitales ' },
            { 'clave': 'I08', 'description': 'Otra maquinaria y equipo ' },
            { 'clave': 'D01', 'description': 'Honorarios médicos, dentales y gastos hospitalarios. ' },
            { 'clave': 'D02', 'description': 'Gastos médicos por incapacidad o discapacidad ' },
            { 'clave': 'D03', 'description': 'Gastos funerales. ' },
            { 'clave': 'D04', 'description': 'Donativos' },
            { 'clave': 'D05', 'description': 'Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación). ' },
            { 'clave': 'D06', 'description': 'Aportaciones voluntarias al SAR. ' },
            { 'clave': 'D07', 'description': 'Primas por seguros de gastos médicos. ' },
            { 'clave': 'D08', 'description': 'Gastos de transportación escolar obligatoria. ' },
            { 'clave': 'D09', 'description': 'Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones. ' },
            { 'clave': 'D10', 'description': 'Pagos por servicios educativos (colegiaturas) ' },
            { 'clave': 'P01', 'description': 'Por definir ' }
        ]

    };
    let type = array.usecfdi.filter(el => el.clave === tipo);
    let typeuse = type[0];
    // console.log(type);
    let textcfdi = `${typeuse['clave']} - ${typeuse['description']}`;
    return textcfdi;
}

export function metodospago(texto) {

    let array = {
        'usecfdi': [
            { 'clave': 'PPD', 'description': 'Pago en Parcialidades o Diferido' },
            { 'clave': 'PUE', 'description': 'Pago en Una sola Exhibición' },

        ]

    };
    let type = array.usecfdi.filter(el => el.clave === texto);
    let typeuse = type[0];
    // console.log(type);
    let textcfdi = `${typeuse['clave']} - ${typeuse['description']}`;
    return textcfdi;
}


export function obtenerMes(numero) {
    var meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

    var numeroMes = parseInt(numero);
    let datos;

    if (!isNaN(numeroMes) && numeroMes >= 1 && numeroMes <= 12) {

        datos = meses[numeroMes - 1];


    }
    return datos;

}


export function compararMeses(data) {


    let meses = [
        { id: '1', mes: "Enero" },
        { id: '2', mes: "Febrero" },
        { id: '3', mes: "Marzo" },
        { id: '4', mes: "Abril" },
        { id: '5', mes: "Mayo" },
        { id: '6', mes: "Junio" },
        { id: '7', mes: "Julio" },
        { id: '8', mes: "Agosto" },
        { id: '9', mes: "Septiembre" },
        { id: '10', mes: "Octubre" },
        { id: '11', mes: "Noviembre" },
        { id: '12', mes: "Diciembre" }
    ]

    var data3 = data.filter(function(el) {
        var found = false,
            x = 0;
        while (x < meses.length && !found) {
            if (el.id == meses[x].id && el.mes == meses[x].mes) found = true;
            x++;
        }
        if (!found) return el;
    });

    // añadimos todas las cadenas del segundo array que no estén en el primero
    data3 = data3.concat(meses.filter(function(el) {
        var found = false,
            x = 0;
        while (x < data.length && !found) {
            if (el.id == data[x].id && el.mes == data[x].mes) found = true;
            x++;
        }
        if (!found) return el;
    }));


    return data3;

}

export function setting_process_data(data, dato) {
    let dato_extra;
    for (const key in data) {

        const element = data[key];

        if (element.key_value === dato) {

            dato_extra = element.value;

        }


    }

    return dato_extra;

}
export function validarMeses(texto) {


    let mesid = 0;

    switch (texto) {

        case 'ENERO' || 'enero' || 'Enero':

            mesid = 1;

            break;
        case 'FEBRERO' || 'febrero' || 'Febrero':
            mesid = 2;

            break;
        case 'MARZO' || 'marzo' || 'Marzo':
            mesid = 3;

            break;
        case 'ABRIL' || 'abril' || 'Abril':
            mesid = 4;

            break;
        case 'MAYO' || 'mayo' || 'Mayo':
            mesid = 5;

            break;
        case 'JUNIO' || 'junio' || 'Junio':
            mesid = 6;

            break;
        case 'JULIO' || 'julio' || 'Julio':
            mesid = 7;

            break;
        case 'AGOSTO' || 'agosto' || 'Agosto':
            mesid = 8;

            break;
        case 'SEPTIEMBRE' || 'septiembre' || 'Septiembre':
            mesid = 9;

            break;
        case 'OCTUBRE' || 'octubre' || 'Octubre':
            mesid = 10;

            break;
        case 'NOVIEMBRE' || 'noviembre' || 'Noviembre':
            mesid = 11;

            break;
        case 'DICIEMBRE' || 'diciembre' || 'Diciembre':
            mesid = 12;
            console.log(mesid);
            break;

    }
 
    return mesid;

}