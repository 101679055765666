import { Pipe, PipeTransform } from '@angular/core';




@Pipe({
  name: 'monthNamme'
})
export class MonthNamePipe implements PipeTransform {

  transform(value: string)  {
    var meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

    var numeroMes = parseInt(value);
    let datos;
  
    if(!isNaN(numeroMes) && numeroMes >= 1 && numeroMes <= 12 ){
  
       datos = meses[numeroMes - 1];
  
      
    }
  return datos;
  }

}
