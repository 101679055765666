// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { NgxLoggerLevel } from 'ngx-logger';
export const environment = {
  production: false,
  defaultauth: 'fackbackend',
  firebaseConfig: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: '',
  },
  // api:'https://172.215.138.170/api/',
  // api:'https://40.80.212.178/api/',
  // api:'http://10.1.0.7/api/',
  // api:'http://localhost:3000/api/',
  api:'https://viakable.scvplatform.com/api/',
  // api:'http://localhost/api/api/',
  // api:'https://vcontroltfcm.mx/apirest/api/',
  // api:'https://mbqdatacloud.com/api_volumetricosmbq/api/',
  // api:'https://mbqdatacloud.com/api_volumetricros_linker/api/',
  // api:'https://gimsa.scvplatform.com/api/api/',
  // api:'https://libbey.scvplatform.com/api/api/',
  // api:'http://3.130.135.31/apigimsa/api/',
  // api:'https://.mbqdatacloud.com/api_demo/api/',
  idleTimeInMinutes: 10,
  logLevel: NgxLoggerLevel.OFF,
  serverLogLevel: NgxLoggerLevel.ERROR
  // api:'http://3.130.135.31:3000/api/'

};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
