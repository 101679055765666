import { MenuItem } from './menu.model';


let menu  = JSON.parse(localStorage.getItem('menu')) || [];
export const MENU: any[] = [
  {
  id: 1,
  label: 'MENUITEMS.MENU.TEXT',
  isTitle: true
},
        // dasboarh
      {
            id: 2,
            label: 'MENUITEMS.DASHBOARDS.TEXT',
            icon: 'bx-home-circle',
            type:'1025',
            badge: {
                variant: 'info',
                text: 'MENUITEMS.DASHBOARDS.BADGE',
            },
            subItems: [
                {
                    id: 3,
                    label: 'MENUITEMS.DASHBOARDS.LIST.DEFAULT',
                    link: '/dashboard',
                    parentId: 2
                }
            ]
        },
...menu
];
// export const MENU: MenuItem[] = [
//     {
//         id: 1,
//         label: 'MENUITEMS.MENU.TEXT',
//         isTitle: true
//     },
//     // dashboard
//     {
//         id: 2,
//         label: 'MENUITEMS.DASHBOARDS.TEXT',
//         icon: 'bx-home-circle',
//         badge: {
//             variant: 'info',
//             text: 'MENUITEMS.DASHBOARDS.BADGE',
//         },
//         subItems: [
//             {
//                 id: 3,
//                 label: 'MENUITEMS.DASHBOARDS.LIST.DEFAULT',
//                 link: '/dashboard',
//                 parentId: 2
//             }
//         ]
//     },
//     {
//         id: 7,
//         isLayout: true
//     },
//     {
//         id: 8,
//         label: 'MENUITEMS.APPS.TEXT',
//         isTitle: true
//     },
// // usuarios
//     {
//         id: 12,
//         label: 'MENUITEMS.ECOMMERCE.TEXT',
//         icon: 'bx-user',
//         subItems: [
//             {
//                 id: 13,
//                 label: 'MENUITEMS.ECOMMERCE.LIST.PRODUCTS',
//                 link: '/usuarios/usuarios',
//                 parentId: 12
//             },
//             {
//                 id: 14,
//                 label: 'MENUITEMS.ECOMMERCE.LIST.PRODUCTDETAIL',
//                 link: '/usuarios/permisos',
//                 parentId: 12
//             },
//             {
//                 id: 15,
//                 label: 'MENUITEMS.ECOMMERCE.LIST.ORDERS',
//                 link: '/usuarios/relacion_permisos',
//                 parentId: 12
//             },
//             {
//                 id: 16,
//                 label: 'MENUITEMS.ECOMMERCE.LIST.CUSTOMERS',
//                 link: '/usuarios/perfiles',
//                 parentId: 12
//             }
//         ]
//     },
//     // inventarios
//     {
//         id: 21,
//         label: 'MENUITEMS.CRYPTO.TEXT',
//         icon: 'bxs-box',
//         subItems: [
//             {
//                 id: 22,
//                 label: 'MENUITEMS.CRYPTO.LIST.WALLET',
//                 link: '/inventarios/recepciones',
//                 parentId: 21
//             },
//             {
//                 id: 23,
//                 label: 'MENUITEMS.CRYPTO.LIST.BUY/SELL',
//                 link: '/inventarios/entregas',
//                 parentId: 21
//             },
//             {
//                 id: 24,
//                 label: 'MENUITEMS.CRYPTO.LIST.EXCHANGE',
//                 link: '/inventarios/existencias',
//                 parentId: 21
//             },
//             {
//                 id: 25,
//                 label: 'MENUITEMS.CRYPTO.LIST.LENDING',
//                 link: '/inventarios/productos',
//                 parentId: 21
//             },
//             {
//                 id: 26,
//                 label: 'MENUITEMS.CRYPTO.LIST.ORDERS',
//                 link: '/inventarios/subproductos',
//                 parentId: 21
//             },
//             // {
//             //     id: 27,
//             //     label: 'MENUITEMS.CRYPTO.LIST.KYCAPPLICATION',
//             //     link: '/inventarios/tanques',
//             //     parentId: 21
//             // }
//         ]
//     },
// // catalogos
//     {
//         id: 39,
//         label: 'MENUITEMS.PROJECTS.TEXT',
//         icon: 'bx-book-open',
//         subItems: [
//             {
//                 id: 40,
//                 label: 'MENUITEMS.PROJECTS.LIST.GRID',
//                 link: '/catalogos/departamentos',
//                 parentId: 38
//             },
//             {
//                 id: 41,
//                 label: 'MENUITEMS.PROJECTS.LIST.PROJECTLIST',
//                 link: '/catalogos/unidades_medida',
//                 parentId: 38
//             },
//             {
//                 id: 42,
//                 label: 'MENUITEMS.PROJECTS.LIST.OVERVIEW',
//                 link: '/catalogos/proveedores',
//                 parentId: 38
//             },
//             {
//                 id: 43,
//                 label: 'MENUITEMS.PROJECTS.LIST.CREATE',
//                 link: '/catalogos/equipos',
//                 parentId: 38
//             }
//         ]
//     },
//     // bitacoras
//     {
//         id: 44,
//         label: 'MENUITEMS.TASKS.TEXT',
//         icon: 'bx-task',
//         subItems: [
//             // {
//             //     id: 45,
//             //     label: 'MENUITEMS.TASKS.LIST.TASKLIST',
//             //     link: '/bitacoras/alertas',
//             //     parentId: 44
//             // },
//             {
//                 id: 46,
//                 label: 'MENUITEMS.TASKS.LIST.KANBAN',
//                 link: '/bitacoras/log_navegacion',
//                 parentId: 44
//             },
//             // {
//             //     id: 47,
//             //     label: 'MENUITEMS.TASKS.LIST.CREATETASK',
//             //     link: '/bitacoras/log_peticiones',
//             //     parentId: 44
//             // }
//         ]
//     },
//     // reportes
//     {
//         id: 48,
//         label: 'MENUITEMS.CONTACTS.TEXT',
//         icon: ' bxs-report',
//         subItems: [
//             {
//                 id: 49,
//                 label: 'MENUITEMS.CONTACTS.LIST.USERGRID',
//                 link: '/reportes/grafico_reporte',
//                 parentId: 48
//             },
//             // {
//             //     id: 50,
//             //     label: 'MENUITEMS.CONTACTS.LIST.USERLIST',
//             //     link: '/reportes/reporte_diario',
//             //     parentId: 48
//             // },
//             // {
//             //     id: 51,
//             //     label: 'MENUITEMS.CONTACTS.LIST.PROFILE',
//             //     link: '/reportes/reporte_mensual',
//             //     parentId: 48
//             // }
//         ]
//     },
//     // importaciones
//     {
//         id: 52,
//         label: 'MENUITEMS.BLOG.TEXT',
//         icon: 'bx-file',
//         badge: {
//             variant: 'success',
//             text: 'MENUITEMS.EMAIL.LIST.TEMPLATE.BADGE',
//         },
//         subItems: [
//             {
//                 id: 53,
//                 label: 'MENUITEMS.BLOG.LIST.BLOGLIST',
//                 link: '/importaciones/importacion_diaria',
//                 parentId: 52
//             },
//             {
//                 id: 54,
//                 label: 'MENUITEMS.BLOG.LIST.BLOGGRID',
//                 link: '/importaciones/importacion_mensual',
//                 parentId: 52
//             },

//         ]
//     },
// // mantenimientos
//     {
//         id: 57,
//         label: 'MENUITEMS.AUTHENTICATION.TEXT',
//         icon: 'bx-user-circle',

//         subItems: [
//             {
//                 id: 58,
//                 label: 'MENUITEMS.AUTHENTICATION.LIST.LOGIN',
//                 link: '/mantenimientos/gestion',
//                 parentId: 57
//             },
//             // {
//             //     id: 59,
//             //     label: 'MENUITEMS.AUTHENTICATION.LIST.LOGIN2',
//             //     link: '/account/login-2',
//             //     parentId: 57
//             // },
//             // {
//             //     id: 60,
//             //     label: 'MENUITEMS.AUTHENTICATION.LIST.REGISTER',
//             //     link: '/account/signup',
//             //     parentId: 57
//             // },
//             // {
//             //     id: 61,
//             //     label: 'MENUITEMS.AUTHENTICATION.LIST.REGISTER2',
//             //     link: '/account/signup-2',
//             //     parentId: 57
//             // },
//             // {
//             //     id: 62,
//             //     label: 'MENUITEMS.AUTHENTICATION.LIST.RECOVERPWD',
//             //     link: '/account/reset-password',
//             //     parentId: 57
//             // },
//             // {
//             //     id: 63,
//             //     label: 'MENUITEMS.AUTHENTICATION.LIST.RECOVERPWD2',
//             //     link: '/account/recoverpwd-2',
//             //     parentId: 57
//             // },
//             // {
//             //     id: 64,
//             //     label: 'MENUITEMS.AUTHENTICATION.LIST.LOCKSCREEN',
//             //     link: '/pages/lock-screen-1',
//             //     parentId: 57
//             // },
//             // {
//             //     id: 65,
//             //     label: 'MENUITEMS.AUTHENTICATION.LIST.LOCKSCREEN2',
//             //     link: '/pages/lock-screen-2',
//             //     parentId: 57
//             // },
//             // {
//             //     id: 66,
//             //     label: 'MENUITEMS.AUTHENTICATION.LIST.CONFIRMMAIL',
//             //     link: '/pages/confirm-mail',
//             //     parentId: 57
//             // },
//             // {
//             //     id: 67,
//             //     label: 'MENUITEMS.AUTHENTICATION.LIST.CONFIRMMAIL2',
//             //     link: '/pages/confirm-mail-2',
//             //     parentId: 57
//             // },
//             // {
//             //     id: 68,
//             //     label: 'MENUITEMS.AUTHENTICATION.LIST.EMAILVERIFICATION',
//             //     link: '/pages/email-verification',
//             //     parentId: 57
//             // },
//             // {
//             //     id: 69,
//             //     label: 'MENUITEMS.AUTHENTICATION.LIST.EMAILVERIFICATION2',
//             //     link: '/pages/email-verification-2',
//             //     parentId: 57
//             // },
//             // {
//             //     id: 70,
//             //     label: 'MENUITEMS.AUTHENTICATION.LIST.TWOSTEPVERIFICATION',
//             //     link: '/pages/two-step-verification',
//             //     parentId: 57
//             // },
//             // {
//             //     id: 71,
//             //     label: 'MENUITEMS.AUTHENTICATION.LIST.TWOSTEPVERIFICATION2',
//             //     link: '/pages/two-step-verification-2',
//             //     parentId: 57
//             // }
//         ]
//     },
//     // ajustes
//     {
//         id: 72,
//         label: 'MENUITEMS.UTILITY.TEXT',
//         icon: 'bx-file',
//         subItems: [
//             // {
//             //     id: 73,
//             //     label: 'MENUITEMS.UTILITY.LIST.STARTER',
//             //     link: '/ajustes/cifrado',
//             //     parentId: 72
//             // },
//             {
//                 id: 74,
//                 label: 'MENUITEMS.UTILITY.LIST.MAINTENANCE',
//                 link: '/ajustes/datos_empresa',
//                 parentId: 72
//             },
//             {
//                 id: 75,
//                 label: 'MENUITEMS.UTILITY.LIST.TIMELINE',
//                 link: '/ajustes/salida_email',
//                 parentId: 72
//             },

//         ]
//     },
// // conciliaciones
//     {
//         id: 81,
//         label: 'MENUITEMS.UIELEMENTS.TEXT',
//         icon: 'bx-tone',
//         subItems: [
//             {
//                 id: 82,
//                 label: 'MENUITEMS.UIELEMENTS.LIST.ALERTS',
//                 link: '/conciliaciones/conciliacion',
//                 parentId: 81
//             },
//             {
//                 id: 83,
//                 label: 'MENUITEMS.UIELEMENTS.LIST.BUTTONS',
//                 link: '/conciliaciones/List_conciliaciones',
//                 parentId: 81
//             },
//             {
//                 id: 84,
//                 label: 'MENUITEMS.UIELEMENTS.LIST.CARDS',
//                 link: '/conciliaciones/reversar_conciliacion',
//                 parentId: 81
//             },
//             // {
//             //     id: 85,
//             //     label: 'MENUITEMS.UIELEMENTS.LIST.CAROUSEL',
//             //     link: '/ui/carousel',
//             //     parentId: 81
//             // },
//             // {
//             //     id: 86,
//             //     label: 'MENUITEMS.UIELEMENTS.LIST.DROPDOWNS',
//             //     link: '/ui/dropdowns',
//             //     parentId: 81
//             // },
//             // {
//             //     id: 87,
//             //     label: 'MENUITEMS.UIELEMENTS.LIST.GRID',
//             //     link: '/ui/grid',
//             //     parentId: 81
//             // },
//             // {
//             //     id: 88,
//             //     label: 'MENUITEMS.UIELEMENTS.LIST.IMAGES',
//             //     link: '/ui/images',
//             //     parentId: 81
//             // },
//             // {
//             //     id: 88,
//             //     label: 'MENUITEMS.UIELEMENTS.LIST.LIGHTBOX',
//             //     link: '/ui/lightbox',
//             //     parentId: 81
//             // },
//             // {
//             //     id: 89,
//             //     label: 'MENUITEMS.UIELEMENTS.LIST.MODALS',
//             //     link: '/ui/modals',
//             //     parentId: 81
//             // },
//             // {
//             //     id: 90,
//             //     label: 'MENUITEMS.UIELEMENTS.LIST.RANGESLIDER',
//             //     link: '/ui/rangeslider',
//             //     parentId: 81
//             // },
//             // {
//             //     id: 91,
//             //     label: 'MENUITEMS.UIELEMENTS.LIST.PROGRESSBAR',
//             //     link: '/ui/progressbar',
//             //     parentId: 81
//             // },
//             // {
//             //     id: 92,
//             //     label: 'MENUITEMS.UIELEMENTS.LIST.PLACEHOLDER',
//             //     link: '/ui/placeholder',
//             //     parentId: 81
//             // },
//             // {
//             //     id: 93,
//             //     label: 'MENUITEMS.UIELEMENTS.LIST.SWEETALERT',
//             //     link: '/ui/sweet-alert',
//             //     parentId: 81
//             // },
//             // {
//             //     id: 94,
//             //     label: 'MENUITEMS.UIELEMENTS.LIST.TABS',
//             //     link: '/ui/tabs-accordions',
//             //     parentId: 81
//             // },
//             // {
//             //     id: 95,
//             //     label: 'MENUITEMS.UIELEMENTS.LIST.TYPOGRAPHY',
//             //     link: '/ui/typography',
//             //     parentId: 81
//             // },
//             // {
//             //     id: 96,
//             //     label: 'MENUITEMS.UIELEMENTS.LIST.VIDEO',
//             //     link: '/ui/video',
//             //     parentId: 81
//             // },
//             // {
//             //     id: 97,
//             //     label: 'MENUITEMS.UIELEMENTS.LIST.GENERAL',
//             //     link: '/ui/general',
//             //     parentId: 81
//             // },
//             // {
//             //     id: 98,
//             //     label: 'MENUITEMS.UIELEMENTS.LIST.COLORS',
//             //     link: '/ui/colors',
//             //     parentId: 81
//             // },
//             // {
//             //     id: 99,
//             //     label: 'MENUITEMS.UIELEMENTS.LIST.CROPPER',
//             //     link: '/ui/image-crop',
//             //     parentId: 81
//             // },
//         ]
//     },
//     // reportes
//     {
//         id: 100,
//         label: 'MENUITEMS.FORMS.TEXT',
//         icon: 'bxs-eraser',
//         subItems: [
//             {
//                 id: 101,
//                 label: 'MENUITEMS.FORMS.LIST.ELEMENTS',
//                 link: '/reportes/reporte_mensual',
//                 parentId: 100
//             },
//             {
//                 id: 102,
//                 label: 'MENUITEMS.FORMS.LIST.LAYOUTS',
//                 link: '/reportes/reporte_diario',
//                 parentId: 100
//             }
//             // {
//             //     id: 103,
//             //     label: 'MENUITEMS.FORMS.LIST.VALIDATION',
//             //     link: '/form/validation',
//             //     parentId: 100
//             // },
//             // {
//             //     id: 104,
//             //     label: 'MENUITEMS.FORMS.LIST.ADVANCED',
//             //     link: '/form/advanced',
//             //     parentId: 100
//             // },
//             // {
//             //     id: 105,
//             //     label: 'MENUITEMS.FORMS.LIST.EDITOR',
//             //     link: '/form/editor',
//             //     parentId: 100
//             // },
//             // {
//             //     id: 106,
//             //     label: 'MENUITEMS.FORMS.LIST.FILEUPLOAD',
//             //     link: '/form/uploads',
//             //     parentId: 100
//             // },
//             // {
//             //     id: 107,
//             //     label: 'MENUITEMS.FORMS.LIST.REPEATER',
//             //     link: '/form/repeater',
//             //     parentId: 100
//             // },
//             // {
//             //     id: 108,
//             //     label: 'MENUITEMS.FORMS.LIST.WIZARD',
//             //     link: '/form/wizard',
//             //     parentId: 100
//             // },
//             // {
//             //     id: 109,
//             //     label: 'MENUITEMS.FORMS.LIST.MASK',
//             //     link: '/form/mask',
//             //     parentId: 100
//             // }
//         ]
//     },
//     // cfdis
//     {
//         id: 110,
//         icon: 'bx-list-ul',
//         label: 'MENUITEMS.TABLES.TEXT',
//         subItems: [
//             {
//                 id: 111,
//                 label: 'MENUITEMS.TABLES.LIST.BASIC',
//                 link: '/cfids/listado',
//                 parentId: 110
//             },
//             {
//                 id: 112,
//                 label: 'MENUITEMS.TABLES.LIST.ADVANCED',
//                 link: '/financiero/dictamenes',
//                 parentId: 110
//             }
//         ]
//     },
//     // {
//     //     id: 113,
//     //     icon: 'bxs-bar-chart-alt-2',
//     //     label: 'MENUITEMS.CHARTS.TEXT',
//     //     subItems: [
//     //         {
//     //             id: 114,
//     //             label: 'MENUITEMS.CHARTS.LIST.APEX',
//     //             link: '/charts/apex',
//     //             parentId: 113
//     //         },
//     //         {
//     //             id: 115,
//     //             label: 'MENUITEMS.CHARTS.LIST.CHARTJS',
//     //             link: '/charts/chartjs',
//     //             parentId: 113
//     //         },
//     //         {
//     //             id: 116,
//     //             label: 'MENUITEMS.CHARTS.LIST.CHARTIST',
//     //             link: '/charts/chartist',
//     //             parentId: 113
//     //         },
//     //         {
//     //             id: 117,
//     //             label: 'MENUITEMS.CHARTS.LIST.ECHART',
//     //             link: '/charts/echart',
//     //             parentId: 113
//     //         }
//     //     ]
//     // },
//     // {
//     //     id: 118,
//     //     label: 'MENUITEMS.ICONS.TEXT',
//     //     icon: 'bx-aperture',
//     //     subItems: [
//     //         {
//     //             id: 119,
//     //             label: 'MENUITEMS.ICONS.LIST.BOXICONS',
//     //             link: '/icons/boxicons',
//     //             parentId: 118
//     //         },
//     //         {
//     //             id: 120,
//     //             label: 'MENUITEMS.ICONS.LIST.MATERIALDESIGN',
//     //             link: '/icons/materialdesign',
//     //             parentId: 118
//     //         },
//     //         {
//     //             id: 121,
//     //             label: 'MENUITEMS.ICONS.LIST.DRIPICONS',
//     //             link: '/icons/dripicons',
//     //             parentId: 118
//     //         },
//     //         {
//     //             id: 122,
//     //             label: 'MENUITEMS.ICONS.LIST.FONTAWESOME',
//     //             link: '/icons/fontawesome',
//     //             parentId: 118
//     //         },
//     //     ]
//     // },
//     // {
//     //     id: 123,
//     //     label: 'MENUITEMS.MAPS.TEXT',
//     //     icon: 'bx-map',
//     //     subItems: [
//     //         {
//     //             id: 124,
//     //             label: 'MENUITEMS.MAPS.LIST.GOOGLEMAP',
//     //             link: '/maps/google',
//     //             parentId: 123
//     //         }
//     //     ]
//     // },
//     // {
//     //     id: 125,
//     //     label: 'MENUITEMS.MULTILEVEL.TEXT',
//     //     icon: 'bx-share-alt',
//     //     subItems: [
//     //         {
//     //             id: 126,
//     //             label: 'MENUITEMS.MULTILEVEL.LIST.LEVEL1.1',
//     //             link: '#',
//     //             parentId: 125
//     //         },
//     //         {
//     //             id: 127,
//     //             label: 'MENUITEMS.MULTILEVEL.LIST.LEVEL1.2',
//     //             parentId: 125,
//     //             subItems: [
//     //                 {
//     //                     id: 128,
//     //                     label: 'MENUITEMS.MULTILEVEL.LIST.LEVEL1.LEVEL2.1',
//     //                     parentId: 127,
//     //                 },
//     //                 {
//     //                     id: 129,
//     //                     label: 'MENUITEMS.MULTILEVEL.LIST.LEVEL1.LEVEL2.2',
//     //                     parentId: 127,
//     //                 }
//     //             ]
//     //         },
//     //     ]
//     // }
// ];

