import { NgModule,LOCALE_ID  } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CifradoPipe, DecryptPipe } from './cifrado.pipe';
import { ImagenPipe } from './imagen.pipe';
import { niceDateFormatPipe } from './niceDateFormat.pipe';

import { FormatFileSizePipe } from './FormatFileSizePipe .pipe';
import { MonthNamePipe } from './nameMonth.pipe';
import { ImagenCompanyPipe } from './imagenCompany.pipe';
import { XmlPipe } from './xml.pipe';
import { KeyPipePipe } from './key-pipe.pipe';
import { NumberFormatPipe } from './NumberFormat.pipe';



@NgModule({
  declarations: [
    DecryptPipe,
    CifradoPipe,
    ImagenPipe,
    niceDateFormatPipe,
    FormatFileSizePipe,
    MonthNamePipe,
    ImagenCompanyPipe,
    XmlPipe,
    KeyPipePipe,
    NumberFormatPipe

  ],
  imports: [
    CommonModule
  ],
  exports:[
    DecryptPipe,
    CifradoPipe,
    ImagenPipe,
    niceDateFormatPipe,
    FormatFileSizePipe,
    MonthNamePipe,
    ImagenCompanyPipe,
    XmlPipe,
    KeyPipePipe,
    NumberFormatPipe
  ],
  providers: [

]
})
export class PipesModule { }
