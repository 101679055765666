import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

  const base = environment.api;

@Injectable({
  providedIn: 'root'
})

export class MantenimientosService {

  constructor(private http:HttpClient) { }
  get token(): string {
    return localStorage.getItem('token') || '';
  }

  get headers() {
    return {
      headers: {
        'x-token': this.token
      }
    }
  }


  getMantenimientos(){
    let base_url  = `${base}mantenimientos`
    return this.http.get(base_url,this.headers);

  }
  postMantenimiento(mantenimiento:any){
    let base_url  = `${base}mantenimientos`
    return this.http.post(base_url,mantenimiento,this.headers);
  }


  getMantenimientosBYID(id:any){
    let base_url  = `${base}mantenimientos/${id}`
    return this.http.get(base_url,this.headers);

  }


  updateData(body:any,id:any){
    let base_url  = `${base}mantenimientos/${id}`
    return this.http.put(base_url,body,this.headers);
  }
  updateFiles(body:any,id:any){
    let base_url  = `${base}mantenimientos/evidencias/${id}`
    return this.http.put(base_url,body,this.headers);
  }
  updateStatus(body:any,id:any){
    let base_url  = `${base}mantenimientos/status/${id}`
    return this.http.put(base_url,body,this.headers);
  }

  getFiles(id:any){
    let base_url  = `${base}mantenimientos/files/${id}`
    return this.http.get(base_url,this.headers);
  }

  
  getDownloadfile(id:any){
    let base_url = `${base}mantenimientos/download/file/${id}`;
    return this.http.get(base_url,{headers:{'x-token': this.token}, responseType:'blob'});
  }

  getMantenimientosAlertas(){
    let base_url = `${base}alertasMantenimientos`;
    return this.http.get(base_url,this.headers);
  }
  getVigenciaAlertas(){
    let base_url = `${base}alertasVigenciasdeCalibracionMed`;
    return this.http.get(base_url,this.headers);
  }

  getAlarmas(){
    let base_url = `${base}alamardeError`;
    return this.http.get(base_url,this.headers);
  }
}
