<div class="account-pages my-5 pt-sm-5">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8 col-lg-6 col-xl-5">
                <div class="card overflow-hidden">
                    <div class="bg-soft bg-primary">
                        <div class="row">
                            <div class="col-7">
                                <div class="text-primary p-4">
                                    <h5 class="text-primary"> Cambio de Contraseña</h5>
                                    <p>MQB.</p>
                                </div>
                            </div>
                            <div class="col-5 align-self-end">
                                <img src="assets/images/profile-img.png" alt="" class="img-fluid">
                            </div>
                        </div>
                    </div>
                    <div class="card-body pt-0">
                        <div>
                            <a routerLink="/">
                                <div class="avatar-md profile-user-wid mb-4">
                                    <span class="avatar-title rounded-circle bg-light">
                                        <img src="assets/images/volumetricos.png" height="34" width="120">
                  </span>
                                </div>
                            </a>
                        </div>

                        <form class="needs-validation" name="resetForm" [formGroup]="resetForm" (ngSubmit)="onSubmit()" novalidate>

                            <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>

                            <div class="mb-3">
                                <label>Password</label>

                                <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password2.errors }" id="password" placeholder="Nueva Contraseña" />

                                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                    <div *ngIf="f.password.errors.required">Password es requerida</div>
                                    <div *ngIf="f.password.errors.pattern"> La contraseña debe contener al menos un número, una mayúscula, un carácter especial y una letra minúscula y al menos 8 caracteres<br/>La contraseña no puede contener espacios en blanco</div>
                                </div>
                            </div>

                            <div class="mb-3">
                                <label>Confirmar Password</label>

                                <input type="password" formControlName="password2" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password2.errors }" id="password2" placeholder="Confirmar Contraseña" />

                                <div *ngIf="submitted && f.password2.errors" class="invalid-feedback">
                                    <div *ngIf="f.password2.errors.required">Passwword  de condfirmacion es requerida</div>
                                    <div *ngIf="f.password2.errors.confirmedValidator">La contraseña y la confirmación de contraseña deben coincidir.</div>
                                </div>
                            </div>
                            <div class="text-end">
                                <button class="btn btn-primary w-md" type="submit">Cambiar</button>
                            </div>
                        </form>

                    </div>
                    <!-- end card-body-->
                </div>
                <!-- end card -->
                <div class="mt-5 text-center">
                    <!-- <p>Remember It ? <a type="button" routerLink="/account/login" class="fw-medium text-primary"> Sign In here</a> </p> -->
                    <img src="assets/images/volumetricos.png" height="50" width="120">
                    <p>© {{year}} SGP. Desing by MBQ</p>
                </div>

            </div>
            <!-- end col -->
        </div>
        <!-- end row -->
    </div>
    <!-- end container -->
</div>
<!-- end page -->