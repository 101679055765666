import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberFormat',
})
export class NumberFormatPipe implements PipeTransform {

  transform(value: number | string, locale: string = 'en-US'): unknown {
    if (value == null) return '';

    // Convertimos el valor a número si es necesario
    const numberValue = typeof value === 'string' ? parseFloat(value) : value;

    // Usamos Intl.NumberFormat para aplicar el formato según la configuración regional
    return new Intl.NumberFormat(locale).format(numberValue);
  }
  }


