import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of, interval, BehaviorSubject } from 'rxjs';
import { tap, map, catchError, first } from 'rxjs/operators';
import { environment } from '../../../environments/environment.prod';
import { setting_process_data } from './../../pages/functions/functions';
import { LogsService } from './logs.service';
import { UserProfileService } from './user.service';
import  { detectBrowserName,Decrypt,Encrypt }  from './../../pages/functions/functions';

 const base_url = environment.api;
@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  public connected$ = new BehaviorSubject<boolean>(false);
  private config = '';
  public connState: boolean;
  private source  = interval(parseInt(localStorage.getItem('interval'))) || interval(3000);
  public cifrado:any = [];
  public dataSettings: any;
  public datalabel:any = []
  public dataLineal:any = []
  setting;
    constructor(private http:HttpClient,private logsService:LogsService, private userProfileService:UserProfileService) {
    this.connected$.subscribe(connected => {
      // console.log("Connected: ", connected);
    });

   }

   connected(data: boolean) {
    this.connState = data;
    this.connected$.next(this.connState);
  }

  get token(): string {
    return localStorage.getItem('token') || '';
  }


  get headers() {
    return {
      headers: {
        'x-token': this.token
      }
    }
  }


  async getSettingsFilter(){

    let base = `${base_url}settings`;
    let result =  await  this.http.get(base,this.headers).toPromise();
    let dato =  setting_process_data(result, 'send_password_all_users');
    let tempPassoword =  setting_process_data(result, 'password_temporal');
    localStorage.setItem('send_password_all_users',Encrypt(dato));
    localStorage.setItem('tempssps',Encrypt(tempPassoword));
 }


 
 

  async getSettings(){

     let base = `${base_url}settings`;
     let result =  await  this.http.get(base,this.headers).toPromise();
     localStorage.setItem('interval',setting_process_data(result, 'interval'));
    return result;
  }


  
 
  async getSettingsImageLogo(){

     let base = `${base_url}imagecompany`;
     let result =  await  this.http.get(base).toPromise();
    return result;
  }


  updateinfoCompany(company){
    let base = `${base_url}settings/value`;
    let body = {
      detail:'Actualizacion de datos de compañia ',
      msg:'Se actualizo informacion de la compañia',
      user_id:this.userProfileService.usuario.id,
      register_id:'',
      consult:'',
      created_at:new Date(),
      petition:'POST',
      navegator:detectBrowserName(),
      is_login:null
    }
      this.logsService.postLogs(body);
   return this.http.post(base,company,this.headers);
  }

  allSettings(){
    let base = `${base_url}settings`;
    let body = {
      detail:'Consulta  de ajuste  ',
      msg:'Se consulto información de ajustes',
      user_id:this.userProfileService.usuario.id,
      register_id:'',
      consult:'',
      created_at:new Date(),
      petition:'GET',
      navegator:detectBrowserName(),
      is_login:null
    }
      this.logsService.postLogs(body);
   return this.http.get(base,this.headers);
  }

  getInfoCompany(){
    let base = `${base_url}settings/company`;
    let body = {
      detail:'Consulta  de información de la comopañia  ',
      msg:'Se consulto información ',
      user_id:this.userProfileService.usuario.id,
      register_id:'',
      consult:'',
      created_at:new Date(),
      petition:'GET',
      navegator:detectBrowserName(),
      is_login:null
    }
      this.logsService.postLogs(body);
    return this.http.get(base,this.headers);
  }

  validTypeCatarater(caracter,data){

    // console.log(caracter);

    switch (caracter) {

      case 'permisionario':
        for (const key in data) {
          // if (Object.prototype.hasOwnProperty.call(object, key)) {
            // console.log(data);
            const caracterElements = data[key];

              if(caracterElements.key_value ==='num_permiso'){
                localStorage.setItem('num_permiso',caracterElements.value);
              }
              if(caracterElements.key_value ==='caracter'){
                localStorage.setItem('caracter',caracterElements.value);

              }

              if(caracterElements.key_value ==='rfc'){
                localStorage.setItem('rfc_contribuyente',caracterElements.value);

              }
              if(caracterElements.key_value ==='representante_legal'){
                localStorage.setItem('representante_legal',caracterElements.value);

              }
              if(caracterElements.key_value ==='modalidad_permiso'){
                localStorage.setItem('modalidad_permiso',caracterElements.value);
              }
          // }
        }



        break;

    }


  }

  getMail(){
    let base = `${base_url}settings_mail`;
    let body = {
      detail:'Consulta  la configuración de EMAIL  ',
      msg:'Actulización de información ',
      user_id:this.userProfileService.usuario.id,
      register_id:'',
      consult:'',
      created_at:new Date(),
      petition:'PUT',
      navegator:detectBrowserName(),
      is_login:null
    }
      this.logsService.postLogs(body);
    return this.http.get(base,this.headers);
  }

  updateinfoMail(mail){
    let base = `${base_url}settings_mail`;
    let body = {
      detail:'Actualización  la configuración de EMAIL  ',
      msg:'Actulización de información ',
      user_id:this.userProfileService.usuario.id,
      register_id:'',
      consult:'',
      created_at:new Date(),
      petition:'PUT',
      navegator:detectBrowserName(),
      is_login:null
    }
      this.logsService.postLogs(body);
   return this.http.post(base,mail,this.headers);
  }

  postLogConnection(data){

     this.http.post(`${base_url}logs_connection`, data).subscribe(resp =>{resp});

  }

  getlogConection(){

    return this.http.get(`${base_url}logs_connection`,this.headers);
  }

  savelogConnection(data:any){

    return this.http.post(`${base_url}settings_connection`,data,this.headers);

  }
  uodatesftp(data:any){
    let base = `${base_url}settings/company`;
    let body = {
      detail:'Actualización  la configuración de ftp  ',
      msg:'Actulización de información ',
      user_id:this.userProfileService.usuario.id,
      register_id:'',
      consult:'',
      created_at:new Date(),
      petition:'PUT',
      navegator:detectBrowserName(),
      is_login:null
    }
      this.logsService.postLogs(body);
    return this.http.put(`${base_url}connection_ftp`,data,this.headers);
  }

  getDataSFTP(){
    return this.http.get(`${base_url}sftp`,this.headers);

  }

  getFilesSFTP(){
    return this.http.get(`${base_url}conecttion`,this.headers);

  }

  processSFTP(name:any){
    let base = `${base_url}settings/company`;
    let body = {
      detail:'Descargar  de archivo FTP  ',
      msg:'Se consult FTP ',
      user_id:this.userProfileService.usuario.id,
      register_id:'',
      consult:'',
      created_at:new Date(),
      petition:'OTHER',
      navegator:detectBrowserName(),
      is_login:null
    }
      this.logsService.postLogs(body);
    return this.http.get(`${base_url}downloandFile/${name}`,this.headers);

  }

  async putLogo(archivo: File){
    try {
    let base = `${base_url}settings/logo`;
    let body = {
      detail:'Cambio de Logo  ',
      msg:'Cambio el logo',
      user_id:this.userProfileService.usuario.id,
      register_id:'',
      consult:'',
      created_at:new Date(),
      petition:'OTHER',
      navegator:detectBrowserName(),
      is_login:null
    }
      this.logsService.postLogs(body);

      const formData = new FormData();
      formData.append('imagen', archivo,archivo.name);
    const resp:any = this.http.put(`${base}`,formData,this.headers).toPromise();
    const data = await resp;

    if ( data.ok ) {
      return data.nombreArchivo;
    } else {

      return false;
    }

  } catch (error) {

    let body = {
      detail:'Error cambio de Logo  ',
      msg:'Error cambio de logo',
      user_id:this.userProfileService.usuario.id,
      register_id:'',
      consult:'',
      created_at:new Date(),
      petition:'OTHER',
      navegator:detectBrowserName(),
      is_login:null
    }
    this.logsService.postLogs(body);
    return false;
    }
  }


  putConfig(data:any){
    let base = `${base_url}settings/update/config`;
    let body = {
      detail:'Habilitar el envio de mail ',
      msg:'Se habilito el envio del mail de usuarios',
      user_id:this.userProfileService.usuario.id,
      register_id:'',
      consult:'',
      created_at:new Date(),
      petition:'PUT',
      navegator:detectBrowserName(),
      is_login:null
    }
      this.logsService.postLogs(body);
    return this.http.put(base,data,this.headers);
  }


  putConfigCertificate(data:any){
    let base = `${base_url}settings/update/certificate`;
    let body = {
      detail:'Actualizacion del certificado de aplicación',
      msg:'Se actuaizo el certificado de la app',
      user_id:this.userProfileService.usuario.id,
      register_id:'',
      consult:'',
      created_at:new Date(),
      petition:'PUT',
      navegator:detectBrowserName(),
      is_login:null
    }
      this.logsService.postLogs(body);
    return this.http.put(base,data,this.headers);
  }


  
  downloadCertificate(name){
    let base = `${base_url}certifcate/download/system`;
    let body = {
      detail:'Visualización de certificado',
      msg:'Ver certificado',
      user_id:this.userProfileService.usuario.id,
      register_id:'',
      consult:'',
      created_at:new Date(),
      petition:'PUT',
      navegator:detectBrowserName(),
      is_login:null
    }
      this.logsService.postLogs(body);
    return this.http.get(`${base}/${name}`,{headers:{'x-token': this.token}, responseType:'blob'});
  }


}
