import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  public menusss:any [] = [];

  cargarMenu() {

    let   menus  = JSON.parse(localStorage.getItem('menu')) || [];
    this.menusss = [
      {
      id: 1,
      label: 'MENUITEMS.MENU.TEXT',
      isTitle: true
    },
            // dasboarh
          {
                id: 2,
                label: 'MENUITEMS.DASHBOARDS.TEXT',
                icon: 'bx-home-circle',
                type:'1025',
                badge: {
                    variant: 'info',
                    text: 'MENUITEMS.DASHBOARDS.BADGE',
                },
                subItems: [
                    {
                        id: 3,
                        label: 'MENUITEMS.DASHBOARDS.LIST.DEFAULT',
                        link: '/dashboard',
                        parentId: 2
                    }
                ]
            },
    ...menus
    ];


  }

  // menu: any[] = [
  //   {
  //     titulo: 'Dashboard',
  //     icono: 'mdi mdi-gauge',
  //     submenu: [
  //       { titulo: 'Main', url: '/' },
  //       { titulo: 'Gráficas', url: 'grafica1' },
  //       { titulo: 'rxjs', url: 'rxjs' },
  //       { titulo: 'Promesas', url: 'promesas' },
  //       { titulo: 'ProgressBar', url: 'progress' },
  //     ]
  //   },

  //   {
  //     titulo: 'Mantenimientos',
  //     icono: 'mdi mdi-folder-lock-open',
  //     submenu: [
  //       { titulo: 'Usuarios', url: 'usuarios' },
  //       { titulo: 'Hospitales', url: 'hospitales' },
  //       { titulo: 'Médicos', url: 'medicos' },
  //     ]
  //   },
  // ];

}
