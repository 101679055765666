<div class="account-pages my-5 pt-sm-5">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
          <div class="bg-soft bg-primary">
            <div class="row">
              <div class="col-7">
                <div class="text-primary p-4">
                  <h5 class="text-primary">Welcome Back !</h5>
                  <p>Sign in to continue to Skote.</p>
                </div>
              </div>
              <div class="col-5 align-self-end">
                <img src="assets/images/profile-img.png" alt="" class="img-fluid">
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div>
              <a routerLink="/">
                <div class="avatar-md profile-user-wid mb-4">
                  <span class="avatar-title rounded-circle bg-light">
                    <img src="assets/images/logo.svg" alt="" class="rounded-circle" height="34">
                  </span>
                </div>
              </a>
            </div>
            <div class="p-2">
              <form class="form-horizontal" [formGroup]="loginForm" (ngSubmit)="onSubmit()">

                <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>

                <div class="mb-3">
                  <label for="email">Email</label>
                  <input type="email" formControlName="email" class="form-control" id="email" placeholder="Email"
                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                  <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">Email is required</div>
                    <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                  </div>
                </div>

                <div class="mb-3">
                  <label for="password">Password</label>
                  <div class="input-group auth-pass-inputgroup">
                    <input type="password" class="form-control" formControlName="password" placeholder="Enter password"
                      [ngClass]="{ 'is-invalid': submitted && f.password.errors }" aria-label="Password"
                      aria-describedby="password-addon">

                    <button class="btn btn-light ms-0" type="button" id="password-addon"><i
                        class="mdi mdi-eye-outline"></i></button>
                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                      <span *ngIf="f.password.errors.required">Password is required</span>
                    </div>
                  </div>
                </div>

                <div class="form-check">
                  <input class="form-check-input" type="checkbox" id="remember-check">
                  <label class="form-check-label" for="remember-check">
                      Remember me
                  </label>
              </div>

                <div class="mt-3 d-grid">
                  <button class="btn btn-primary" type="submit">Log In</button>
                </div>
                <div class="mt-4 text-center">
                  <h5 class="font-size-14 mb-3">Sign in with</h5>

                  <ul class="list-inline">
                    <li class="list-inline-item">
                      <a href="javascript::void()" class="social-list-item bg-primary text-white border-primary">
                        <i class="mdi mdi-facebook"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a href="javascript::void()" class="social-list-item bg-info text-white border-info">
                        <i class="mdi mdi-twitter"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a href="javascript::void()" class="social-list-item bg-danger text-white border-danger">
                        <i class="mdi mdi-google"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="mt-4 text-center">
                  <a routerLink="/account/reset-password" class="text-muted"><i class="mdi mdi-lock mr-1"></i> Forgot
                    your
                    password?</a>
                </div>
              </form>
            </div>

          </div>
        </div>
        <div class="mt-5 text-center">
          <p>Don't have an account ? <a routerLink="/account/signup" class="fw-medium text-primary"> Signup
              now
            </a> </p>
          <p>© {{year}} Skote. Crafted with <i class="mdi mdi-heart text-danger"></i> by Themesbrand</p>
        </div>

      </div>
    </div>
  </div>

  <!-- end container -->
</div>
<!-- end page -->