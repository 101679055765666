import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';



const base_url = environment.api;

@Injectable({
  providedIn: 'root'
})
export class LogsSessionService {

  constructor(private http:HttpClient) { }

  get token(): string {
    return localStorage.getItem('token') || '';
  }

  get headers() {
    return {
      headers: {
        'x-token': this.token
      }
    }
  }
  postLogs_Sessions(log:any){
    let base = `${base_url}logs_session`;
    return this.http.post(base,log);
  }
  getLogs_Sessions(){
    let base = `${base_url}logs_session`;
    return this.http.get(base,this.headers);
  }

    getLogsGeneral(){
    let base = `${base_url}logs`;
    return this.http.get(base,this.headers);
  }
  
  getLogsGeneralRange(from:any,to:any){
    let base = `${base_url}eventosRango/${from}/${to}`;
    return this.http.get(base,this.headers);
  }
  getLogsGeneralAll(){
      let base = `${base_url}eventosAllReport`;
    return this.http.get(base,this.headers);
  }
  getLogsNavegacionRange(from:any,to:any){
    let base = `${base_url}eventosRangoNavegacion/${from}/${to}`;
    return this.http.get(base,this.headers);
  }
  getLogsNavegacionAll(){
      let base = `${base_url}eventosAllReportNavegacion`;
    return this.http.get(base,this.headers);
  }

}
