import { Injectable, Pipe } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Route } from '@angular/router';
import { UserProfileService } from 'src/app/core/services/user.service';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private usuarioService: UserProfileService
    ) { }

    canLoad(route: Route, segments: import("@angular/router").UrlSegment[]): boolean | import("@angular/router").UrlTree | import("rxjs").Observable<boolean | import("@angular/router").UrlTree> | Promise<boolean | import("@angular/router").UrlTree> {
      return this.usuarioService.validarToken()
          .pipe(
            tap( estaAutenticado =>  {
              if ( !estaAutenticado ) {
                this.router.navigateByUrl('/account/login');
              }
            })
          );
    }

    canActivate(next: ActivatedRouteSnapshot,
      state: RouterStateSnapshot) {
        return this.usuarioService.validarToken()
        .pipe(
          tap( estaAutenticado =>  {


            if ( !estaAutenticado ) {
      
            
              this.router.navigateByUrl('/account/login');
            }
          })
        );
    }
}
