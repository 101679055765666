<div class="container-fluid p-0">
    <div class="row g-0">

        <div class="col-xl-9">
            <div class="auth-full-bg pt-lg-5 p-4">
                <div class="w-100">
                    <div class="bg-overlay"></div>
                    <div class="d-flex h-100 flex-column">

                        <div class="p-4 mt-auto">
                            <div class="row justify-content-center">
                                <div class="col-lg-7">
                                    <div class="text-center">

                                        <h4 class="mb-3"><i
                                                class="bx bxs-quote-alt-left text-primary h1 align-middle me-3"></i><span
                                                class="text-primary">5k</span>+ Satisfied clients</h4>

                                        <div dir="ltr">
                                            <owl-carousel-o class="owl-theme auth-review-carousel"
                                                [options]="carouselOption">
                                                <ng-template carouselSlide>
                                                    <div class="item">
                                                        <div class="py-3">
                                                            <p class="font-size-16 mb-4">" Fantastic theme with a
                                                                ton of
                                                                options. If you just want the HTML to integrate with
                                                                your project, then this is the package. You can find
                                                                the
                                                                files in the 'dist' folder...no need to install git
                                                                and
                                                                all the other stuff the documentation talks about. "
                                                            </p>

                                                            <div>
                                                                <h4 class="font-size-16 text-primary">Abs1981</h4>
                                                                <p class="font-size-14 mb-0">- Skote User</p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </ng-template>
                                                <ng-template carouselSlide>
                                                    <div class="item">
                                                        <div class="py-3">
                                                            <p class="font-size-16 mb-4">" If Every Vendor on Envato
                                                                are
                                                                as supportive as Themesbrand, Development with be a
                                                                nice
                                                                experience. You guys are Wonderful. Keep us the good
                                                                work. "</p>

                                                            <div>
                                                                <h4 class="font-size-16 text-primary">nezerious</h4>
                                                                <p class="font-size-14 mb-0">- Skote User</p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </ng-template>
                                            </owl-carousel-o>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end col -->

        <div class="col-xl-3">
            <div class="auth-full-page-content p-md-5 p-4">
                <div class="w-100">

                    <div class="d-flex flex-column h-100">
                        <div class="mb-4 mb-md-5">
                            <a routerLink="/" class="d-block auth-logo">
                                <img src="assets/images/logo-dark.png" alt="" height="18" class="auth-logo-dark">
                                <img src="assets/images/logo-light.png" alt="" height="18" class="auth-logo-light">
                            </a>
                        </div>
                        <div class="my-auto">
                            <div>
                                <h5 class="text-primary">Register account</h5>
                                <p class="text-muted">Get your free Skote account now.</p>
                            </div>
                            <div class="mt-4">
                                <form [formGroup]="signupForm" (ngSubmit)="onSubmit()">
                                    <ngb-alert type="success" *ngIf="successmsg" [dismissible]="false">Registeration
                                        successfull.
                                    </ngb-alert>

                                    <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>

                                    <div class="mb-3">
                                        <label for="username">Username</label>
                                        <input type="text" formControlName="username" class="form-control" id="username"
                                            [ngClass]="{ 'is-invalid': submitted && f.username.errors }"
                                            placeholder="Enter username">
                                        <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                                            <div *ngIf="f.username.errors.required">Userame is required</div>
                                        </div>
                                    </div>

                                    <div class="mb-3">
                                        <label for="useremail">Email</label>
                                        <input type="email" formControlName="email"
                                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                                            class="form-control" id="useremail" placeholder="Enter email">
                                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                            <div *ngIf="f.email.errors.required">Email is required</div>
                                            <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                                        </div>
                                    </div>

                                    <div class="mb-3">
                                        <label for="userpassword">Password</label>
                                        <input type="password" formControlName="password"
                                            [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                                            class="form-control" id="userpassword" placeholder="Enter password">
                                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                            <div *ngIf="f.password.errors.required">Password is required</div>
                                        </div>
                                    </div>

                                    <div>
                                        <p class="mb-0">By registering you agree to the Skote <a
                                                href="javascript: void(0);" class="text-primary">Terms of Use</a>
                                        </p>
                                    </div>

                                    <div class="mt-4 d-grid">
                                        <button class="btn btn-primary btn-block" type="submit">Register</button>
                                    </div>

                                    <div class="mt-4 text-center">
                                        <h5 class="font-size-14 mb-3">Sign up using</h5>
                                        <ul class="list-inline">
                                            <li class="list-inline-item">
                                                <a href="javascript::void()"
                                                    class="social-list-item bg-primary text-white border-primary">
                                                    <i class="mdi mdi-facebook"></i>
                                                </a>
                                            </li>
                                            <li class="list-inline-item">
                                                <a href="javascript::void()"
                                                    class="social-list-item bg-info text-white border-info">
                                                    <i class="mdi mdi-twitter"></i>
                                                </a>
                                            </li>
                                            <li class="list-inline-item">
                                                <a href="javascript::void()"
                                                    class="social-list-item bg-danger text-white border-danger">
                                                    <i class="mdi mdi-google"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </form>

                                <div class="mt-5 text-center">
                                    <p>Already have an account ? <a routerLink="/account/login-2"
                                            class="fw-medium text-primary"> Login</a> </p>
                                </div>
                            </div>
                        </div>

                        <div class="mt-4 mt-md-5 text-center">
                            <p class="mb-0">© {{year}} Skote. Crafted with <i class="mdi mdi-heart text-danger"></i>
                                by Themesbrand</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end col -->
    </div>
    <!-- end row -->
</div>
<!-- end container-fluid -->