import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const url = environment.api;


@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(private http:HttpClient) { }

  getNotifications(){
 return this.http.get(`${url}notifications`);
  }

  getAutorizations(){
    return this.http.get(`${url}authorizations`)
  }
  putAutoriza(id:any){
    return this.http.put(`${url}authorizations/autorizar/${id}`,{});
  }
  putInvalido(id:any){
    return this.http.put(`${url}authorizations/autorizar/invalid/${id}`,{});
  }
}
