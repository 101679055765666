import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
const base_url = environment.api;

@Pipe({
  name: 'niceDateFormatPipe',
})
export class niceDateFormatPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {

    var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
    var localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);

    let fehcaactual  = `${localISOTime}Z`
    if (!value) { return 'Un largo tiempo'; }
    let time = (Date.parse(fehcaactual) - Date.parse(value)) / 1000;


    if (time < 10) {
      return 'Justo ahora';
    } else if (time < 60) {
      return 'hace un momento';
    }
    const divider = [60, 60, 24, 30, 12];
    const string = [' segundo', ' minuto', ' hora', ' dia', ' mes', ' año'];
    let i;
    for (i = 0; Math.floor(time / divider[i]) > 0; i++) {
      time /= divider[i];
    }
    const plural = Math.floor(time) > 1 ? 's' : '';
    return Math.floor(time) + string[i] + plural + ' atras';
  }


}


