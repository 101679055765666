import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Login2Component } from './auth/login2/login2.component';
import { Recoverpwd2Component } from './auth/recoverpwd2/recoverpwd2.component';
import { PasswordresetComponent } from './auth/passwordreset/passwordreset.component';
import { Page404Component } from '../extrapages/page404/page404.component';

const routes: Routes = [
  { path: 'account/login', component:Login2Component },
  { path: 'account/recoverpwd-2', component:Recoverpwd2Component },
  { path: 'account/password-reset/:id', component:PasswordresetComponent },
  { path: 'account/nopagefound', component:Page404Component },
  { path: '', redirectTo: 'account/login', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountRoutingModule { }
