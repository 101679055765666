import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToastsService {

  constructor( private toastr: ToastrService) { }

   showSucces(title:string,text:string){
    return this.toastr.success(title, text);
  }
   showInfo(title:string,text:string){
    return this.toastr.info(title, text);
  }
   showWarning(title:string,text:string){
    return this.toastr.warning(title, text);
  }
   showDanger(title:string,text:string){
    return this.toastr.error(title, text);
  }
}
