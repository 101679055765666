import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Event, ActivationEnd, RoutesRecognized, NavigationEnd } from '@angular/router';
import { LogsSessionService } from './core/services/logs-session.service';
import { filter, map, take } from 'rxjs/operators';
import { Subscription, interval } from 'rxjs';
import { UserProfileService } from './core/services/user.service';
import Swal from 'sweetalert2';
import { LogsService } from './core/services/logs.service';
import { detectBrowserName, Decrypt , Encrypt  } from './pages/functions/functions';
import { SettingsService } from 'src/app/core/services/settings.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsService } from './core/services/notifications.service';
import { ToastsService } from './core/services/toasts.service';
import { Usuario } from './core/models/usuario.model';
import { ToastrService } from 'ngx-toastr';
import { MantenimientosService } from './core/services/mantenimientos.service';
import { IdleService } from './core/services/IdleService.service';
import { EventosService } from './core/services/eventos.service';
import * as moment from 'moment';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements  OnInit,OnDestroy  {
  titulo:string = '';
  tituloSubs$:Subscription;
  currentRoute: string;
  url:string = '';
  public usuario: Usuario;
  constructor(private router:Router,private route:ActivatedRoute,
    private logservice:LogsSessionService,
    private usuerService:UserProfileService, 
    private logService:LogsService,
    private settingsService:SettingsService,
    private modalService: NgbModal,
    private notificationsService:NotificationsService,
    private toastsService:ToastsService,   
    public toastr: ToastrService,
    private mantenimientosService:MantenimientosService,
    private idleService: IdleService,
    private eventosService:EventosService
    ) {
    // this.tituloSubs$ = this.getArgumentosRuta()
    // .subscribe( ({titulo}) =>{ return titulo; });

  }

   ngOnInit() {
    // this.settingsService.getSettingsFilter();
    // this.settingsService.getSettings();
   
   this.router.events.pipe(filter(event => event instanceof RoutesRecognized))
    .subscribe(async(event) => {
    
      localStorage.setItem('rout',Encrypt(event['url'.toString()]));
      let data = {
        access: await this.getArgumentosRuta(),
        move:"Navegacion",
        observation:event['url'],
        user_id:localStorage.getItem('key_id') ? Decrypt(localStorage.getItem('key_id').toString()) : ''
      }
      let intervalo = 1 * 60 * 1000;
      if(localStorage.getItem('token')){
        this.getAutorizations();
        interval(intervalo)
        .pipe(take(1)) 
        .subscribe(() => {
          this.getAlertsMant();
          // this.getVigencia();
          // this.getAutorizations();
        });
      }
     
       this.logservice.postLogs_Sessions(data).subscribe(resp =>{
        //  console.log(resp);
       })

    });




  }


  separateURL(){
    let urlactual  = document.location.href;

  let divid = urlactual.split('/');
  return divid[4];
  }

  // async  initialIdleSettings() {
  //   let geoip:any =  await this.usuerService.geoip;
  //   let publicip:any = await this.usuerService.publicip;
  //   let timeseconds:number  = 2;

  //   const idleTimeoutInSeconds: number = timeseconds * 60;
  //   this.idleService.startWatching(idleTimeoutInSeconds).subscribe((isTimeOut: boolean) => {
  //     if (isTimeOut) {
      
  //           Swal.fire(
  //             'Adios!',
  //             'La sesión ha sido cerrada por inactividad',
  //             'warning'
  //           )
  //           this.modalService.dismissAll();
  //           this.usuerService.logout();
  //           this.router.navigateByUrl('/account/login');
  //           let navegator = detectBrowserName();
  //           let data = {
  //             user_id:this.usuerService.uid,
  //             msg:'Cierre de Sesión por Inactividad',
  //             petition:'POST',
  //             detail:'Cierre de Sesion por Inactividad',
  //             register_id:this.usuerService.uid,
  //             navegator,
  //             is_login:'0',
  //             ip_origin:publicip.ip,
  //             disconnection:'0',
  //             ipv6:geoip.version,
  //             ip:geoip.ip,
  //             network:geoip.network,
  //             city:geoip.city,
  //             latitude:geoip.latitude,
  //             longitude:geoip.longitude,
  //             region:geoip.region,
  //             timezone:geoip.timezone,
  //             postal:geoip.postal,
  //             status_id:4
  //           }
  //           this.savelog(data);


  //           this.eventosService.bitacora.fechaHoraEvento = moment()
  //           this.eventosService.bitacora.usuarioresponsable = 'System';
  //           this.eventosService.bitacora.tiopevento = "5"
  //           this.eventosService.bitacora.month = moment().format('YYYY-MM')
  //           this.eventosService.bitacora.descripcionevento = `Cierre de sesión por inactividad ${Decrypt(this.usuerService.usuario.username)} `
  //          this.eventosService.registrarEventos()
  //     }
  //   })
  // }


  validExist(){
    return new Promise((resolve,reject)=>{
      this.eventosService.validDateTimeEvent(moment().format('yyyy-MM-DD HH:mm:ss')).subscribe(resp=>{
       resolve(resp)   
      })

    });

  }




  savelog(data:any){
    // console.log(data);
  this.logService.postLogs(data);
  }


async  getArgumentosRuta(){

    return new Promise(resolve =>{
     this.router.events.subscribe((event:Event) =>{
        // console.log(titulo);
        if (event instanceof NavigationEnd) {
          resolve(this.separar( event.url));


      }
      // console.log(event['url']);
        });
      })
   }

   ngOnDestroy(): void {
     this.tituloSubs$.unsubscribe();
   }

   separar(text){
    let texto = text;
    let name = texto.split('/');

    return name[2];
   }

   getAlertsMant(){
    this.mantenimientosService.getMantenimientosAlertas().subscribe((resp:any)=>{
      if(resp.count >= 1 ){
        this.toastsService.showWarning('Alerta',`Tienes ${resp.count} mantenimientos en estado pendiente `)
      }
    })
   }
   getVigencia(){
    this.mantenimientosService.getVigenciaAlertas().subscribe((resp:any)=>{
 
      if(resp.ok){
        this.toastsService.showDanger('Alerta',`Tienes Vigencias de calibracion por vencer o vencidas en sistemas de medicion`)
      }
    })
   }

   alarmaError(){
    this.mantenimientosService.getAlarmas().subscribe((resp:any)=>{
      if(resp.ok){
        for (const key in resp.data) {
            const element = resp.data[key];
              this.toastr.error('Error',`${element.tipoEvento} - ${Decrypt(element.descripcionEvento)}`)
        }
      }
    })
    // getAlarmas
  }

    getAutorizations(){
   this.notificationsService.getAutorizations().subscribe(async (resp:any) => {
      for (const key in resp) {
          const element = resp[key];

           
          if(Decrypt(localStorage.getItem('profile')) === 'Administrador'){
            if(element.status === false){  
             await this.toastsService.showInfo('Solicitud de autorización',`Tienes unas autorizaciones pendientes, solicitada por ${Decrypt(element.name)} ${Decrypt(element.lastname)}`)
            }
          }else{
            console.log(element);
            if(element.token_client && element.status === true && element.is_used === false && element.user_id_client === Number(Decrypt(localStorage.getItem('key_id')))  && element.is_invalid === false){ 
               await this.toastsService.showInfo('Solicitud de autorización',`Ya fue autorizada la peticion utiliza el token antes de que caduque`)
            }
          }
        
      }
    })
   }

   filtertipoEvento(id) {

    let result = [{
            id: 1,
            msg: 'administración del sistema'
        },
        {
            id: 2,
            msg: 'eventos de la UCC'
        },
        {
            id: 3,
            msg: 'eventos relacionados a los programas informáticos'
        },
        {
            id: 4,
            msg: 'eventos de comunicación'
        },
        {
            id: 5,
            msg: 'operaciones cotidianas'
        },
        {
            id: 6,
            msg: 'verificaciones realizadas por la autoridad fiscal o por proveedores autorizados por el SAT'
        },
        {
            id: 7,
            msg: 'exista una diferencia de más de 0.5 % tratándose de Hidrocarburos y Petrolíferos líquidos o de 1 % tratándose de Hidrocarburos y Petrolíferos gaseosos, en el volumen final del periodo, obtenido de sumar al volumen inicial en dicho periodo, las recepciones de producto y restar las entregas de producto, incluyendo las pérdidas por proceso'
        },
        {
            id: 8,
            msg: 'el volumen de existencias registrado al corte del día, es igual al registrado en el corte del día anterior y existen registros de entradas o salidas en el corte del día'
        },
        {
            id: 9,
            msg: ' el volumen de existencias registrado por cada tipo de Hidrocarburo o Petrolífero y sistema de medición es menor a cero'
        },
        {
            id: 10,
            msg: 'el volumen de existencias registrado en el corte del día varía con respecto al corte del día anterior y no existen registros de entradas o salidas en el corte del día'
        },
        {
            id: 11,
            msg: 'el volumen de salidas en un lapso de veinticuatro horas es mayor al volumen de entradas del mismo lapso más el volumen de existencias del corte del día anterior'
        },
        {
            id: 12,
            msg: 'calibración no vigente'
        },
        {
            id: 13,
            msg: ' intento de alteración de cualquier registro'
        },
        {
            id: 14,
            msg: 'registros incompletos o duplicados'
        },
        {
            id: 15,
            msg: ' problemas de comunicación'
        },
        {
            id: 16,
            msg: 'falla del medio de almacenamiento'
        },
        {
            id: 17,
            msg: ' falla en la red de comunicación'
        },
        {
            id: 18,
            msg: ' falla de energía'
        },
        {
            id: 19,
            msg: ' error en la transmisión de información'
        },
        {
            id: 20,
            msg: 'rechazos de inicio de sesión'
        },
        {
            id: 21,
            msg: 'paro de emergencia'
        }
    ]

    let data = result.filter(data => data.id === id);

    return data[0].msg;

}
}
